import React, { useState } from "react";
import loadable from "@loadable/component";
import PropTypes from 'prop-types';
import tags from "./tags";
import cms from "./cms/cms";

const FeaturedJobs = loadable(() => import ("./featuredjobs"));
const FeaturedCompanies = loadable(() => import("./featuredcompanies"));
const MoreJobs = loadable(() => import("./morejobs"));

const Jobs = ({ countryProp, remoteProp, tagProp }) => {
  const [remote, setRemote] = useState(remoteProp);
  const [featuredCountries, setFeaturedCountries] = useState(countryProp || null);

  const updateRemote = async remoteness => {
    const remoteValue = remoteness ? remoteness : null;
    setRemote(remoteValue);
  }

  const updateCountry = async countryValue => {
    const featuredCountry = countryValue ? [countryValue] : null;
    setFeaturedCountries(featuredCountry);
  }

  const localized = () => {
    let countryText = "";
    if (tagProp) {
      const tag = tags[tagProp];
      const tagName = tag ? `${tag.name} ` : "Featured ";
      return `${tagName}${cms.keyword} Jobs`
    } else if (countryProp) {
      if (countryProp.includes("GB")) {
        countryText = " UK";
      } else if (countryProp.includes("US")) {
        countryText = " US"
      } else if (countryProp.includes("CA")) {
        countryText = " Canada";
      } else if (countryProp.includes("DE")) {
        countryText = " Germany";
      }
      return `Featured ${cms.keyword} Jobs${countryText}`;
    } else if (remoteProp) {
      return `Featured Remote ${cms.keyword} Jobs`
    } else {
      return null;
    }
  }

  return <div className="max-w-7xl mx-auto px-4 sm:px-6">
    {!cms.displayFilters || (localized() && !tagProp) ? <></> :
      <div className="my-2 sm:my-4 py-2 sm:py-4 px-4 bg-gray-900 sm:rounded-md sm:flex sm:items-center shadow border-gray-200">
        <div className="font-semibold py-2 text-lg leading-5 text-gray-200 flex items-center">
          <span>Filters</span>
          <svg className="ml-1 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"></path></svg>
        </div>
        <div className="sm:ml-4 my-2 sm:my-0">
          <select onChange={e => updateCountry(e.target.value)} id="remote" className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
            <option value="">Select Country</option>
            <option value="US">United States</option>
            <option value="EU">Europe</option>
            <option value="GB">United Kingdom</option>
            <option value="DE">Germany</option>
            <option value="PL">Poland</option>
            <option value="NL">Netherlands</option>
            <option value="IN">India</option>
            <option value="CH">Switzerland</option>
            <option value="IT">Italy</option>
            <option value="BE">Belgium</option>
            <option value="CA">Canada</option>
            <option value="AU">Australia</option>
            <option value="FR">France</option>
            <option value="PT">Portugal</option>
            <option value="IL">Israel</option>
            <option value="IE">Ireland</option>
            <option value="DK">Denmark</option>
            <option value="NO">Norway</option>
            <option value="SE">Sweden</option>
            <option value="FI">Finland</option>
            <option value="SG">Singapore</option>
            <option value="EE">Estonia</option>
            <option value="BY">Belarus</option>
            <option value="LV">Latvia</option>
            <option value="MT">Malta</option>
            <option value="SV">El Salvador</option>
          </select>
        </div>
        <div className="sm:ml-4 my-2 sm:my-0">
          <select onChange={e => updateRemote(e.target.value)} id="remote" className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
            <option value="">Select Remote</option>
            <option value="remote">Remote</option>
            <option value="partially_remote">Partially Remote</option>
            <option value="on_site">On Site</option>
          </select>
        </div>
      </div>
    }
    <div className="my-5">
      <FeaturedJobs localizedTitle={localized()} countries={featuredCountries} remote={remote} tag={tagProp} />
      <FeaturedCompanies />
      <MoreJobs country={featuredCountries} remote={remote} tag={tagProp}/>
    </div>
  </div>;
}

Jobs.propTypes = {
  countryProp: PropTypes.array,
  remoteProp: PropTypes.string,
  tagProp: PropTypes.string
};

export default Jobs;