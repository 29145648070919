import React from "react";
import Jobs from "../../components/jobs";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import cms from "../../components/cms/cms";

const CanadaPage = () =>
  <Layout>
    <SEO
      keywords={[`${cms.keyword} jobs canada`, `${cms.keyword} developer jobs canada`, `remote ${cms.keyword} jobs canada`, `${cms.keyword} software engineering jobs canada`]}
      title={`${cms.keyword} Jobs in Canada`}
      description={`Looking for a ${cms.keyword} Developer job in Canada? ${cms.siteUrl} is exclusively dedicated to ${cms.keyword} jobs. Find ${cms.keyword} jobs in Toronto, Vancouver, Montreal and other places in Canada.`}
    />
    <Jobs countryProp={["CA"]} />
  </Layout>

export default CanadaPage;